import Service from '../Service'

export default class extends Service {
  constructor (id) {
    const url = ['sales-order', 'orders']
    if (id) {
      url.push(id)
    }
    super(url)
  }

  getLastSO () {
    return this.get(null, '/last-so')
  }

  createDraft (data) {
    return this.post(data, '/draft')
  }

  submit (data) {
    return this.put(data, '/submit')
  }

  updateDraft (data) {
    return this.put(data, '/update-draft')
  }

  getSOandStatus () {
    return this.get(null, '/so-and-status')
  }

  approve () {
    return this.post(null, '/approve')
  }

  finalize () {
    return this.post(null, '/finalize')
  }

  void () {
    return this.post(null, '/void')
  }

  getNewOrders () {
    return this.get(null, '/new-orders')
  }
}
