<template>
  <gf-card>
    <gf-content overflow v-loading="loading">
      <template #title>
        <span class="font-weight-normal">{{ $NumberFormat(fulfillments.map(fm => parseFloat(fm.quantity)).reduce((o, n) => o + n, 0)) }} of {{ $NumberFormat(so.units) }} fulfilled</span>
      </template>
      <template #toolbar>
        <gf-button type="primary" size="sm" v-if="so.fulfilled !== 2" @click="newShipment">New Shipment</gf-button>
      </template>
        <div class="no-shipment" v-if="shipments.length === 0">
          <div class="no-shipment__content">
            <div class="no-shipment__content__left" @click="newShipment">
              <div class="title">Create A New Shipment</div>
              <div class="content">
                <span class="content__text">Create shipments when you have packed some or all the items in this order, and are preparing to fulfil them.</span>
              </div>
            </div>
            <div class="no-shipment__content__right">
              <div class="icon"><i class="el-icon-box"></i></div>
            </div>
          </div>
        </div>
        <div class="no-shipment" v-if="shipments.length === 0">
          <div class="no-shipment__content">
            <div class="no-shipment__content__left">
              <div class="title">Fulfil Shipments</div>
              <div class="content">
                <span class="content__text">When fulfilled, stock levels are reduced, and the shipments will not longer be editable</span>
              </div>
            </div>
            <div class="no-shipment__content__right">
              <div class="icon"><i class="el-icon-truck"></i></div>
            </div>
          </div>
        </div>
        <div class="shipment" v-for="(sm, i) in shipments" :key="sm.id">
          <div class="shipment__content">
            <div class="shipment__content__left" @click="viewShipment(sm.id)">
              <div class="title">Shipment #{{ i + 1 }}</div>
              <div class="content">
                <span class="content__text">Units: {{ $NumberFormat(sm.units) }}</span>
              </div>
            </div>
            <div class="shipment__content__right">
              <el-button-group style="margin-right: 10px">
                <el-button size="small" @click="viewShipment(sm.id)">View</el-button>
                <el-button size="small" @click="editShipment(sm.id)" :disabled="sm.is_fulfilled ? true : false">Edit</el-button>
                <el-button size="small" @click="deleteShipment(sm)" :disabled="sm.is_fulfilled ? true : false">Delete</el-button>
              </el-button-group>
              <el-button size="small" v-if="!sm.is_fulfilled" @click="createFulfillment(sm.id)">Ship</el-button>
              <el-button size="small" v-if="sm.is_fulfilled" @click="deleteFulfillment(sm.id)">Delete Fulfillment</el-button>
            </div>
          </div>
        </div>
    </gf-content>
  </gf-card>
</template>

<style lang="scss">
.no-shipment {
  cursor: pointer;
  margin-bottom: 10px;

  &__content {
    display: flex;
    justify-content: space-between;
    border: 1px solid #c4ced1;
    padding: 20px;
    border-radius: 5px;

    &__left {
      .title {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 6px;
      }

      .content {
        font-size: 14px;
        display: flex;
        flex-direction: column;
      }
    }

    &__right {
      font-size: 50px;
      color: #6e6e6e;
    }
  }
}

.shipment {
  cursor: pointer;
  margin-bottom: 10px;

  &__content {
    display: flex;
    align-items: center;
    border: 1px solid #c4ced1;
    padding: 10px 20px;
    border-radius: 5px;
    transition: .2s all;

    &:hover {
      border: 1px solid #409EFF;
      box-shadow: 0 1px 2px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    }

      &__left {
        flex-grow: 1;

      .title {
        font-size: 16px;
        font-weight: 500;
        margin-right: 10px;
      }

      .content {
        font-size: 14px;
        display: flex;
        flex-direction: column;
      }
    }
  }
}
</style>

<script>
import SalesOrderService from '@/services/v1/SalesOrder'
import SalesShipmentService from '@/services/v1/SalesShipment'

export default {
  props: {
    updateChild: Boolean
  },
  data () {
    return {
      loading: false,

      so: {
        so_number: null,
        invoiced: null,
        totla: null
      },
      shipments: [],
      fulfillments: []
    }
  },
  watch: {
    updateChild: function () {
      if (this.updateChild) {
        this.$emit('update:updateChild', false)

        // do something
        this.getSalesOrder()
      }
    }
  },
  methods: {
    newShipment () {
      this.$router.push({ name: 'shipment-new', params: { id: this.$route.params.id } })
    },
    viewShipment (id) {
      this.$router.push({ name: 'shipment-view', params: { id: this.$route.params.id, sid: id } })
    },
    editShipment (id) {
      this.$router.push({ name: 'shipment-edit', params: { id: this.$route.params.id, sid: id } })
    },
    deleteShipment (sh) {
      this.$confirm('You are attempting to delete this Shipment. This action cannot be undone.', 'Are you sure?', {
        confirmButtonText: 'Yes, Delete Shipment',
        cancelButtonText: 'Cancel'
      }).then(async () => {
        try {
          const ssService = new SalesShipmentService(this.$route.params.id, sh.id)
          await ssService.del()
          this.$message.success(`${this.title} successfully deleted`)
          this.$emit('update:update', true)
          this.getSalesOrder()
        } catch (error) {
          this.$Error(error)
        }
      }).catch(() => {})
    },
    deleteFulfillment (id) {
      this.$confirm('You are attempting to delete the fulfillment of this shipment. This action cannot be undone.', 'Are you sure?', {
        confirmButtonText: 'Yes, Delete Fulfillment',
        cancelButtonText: 'Cancel'
      }).then(async () => {
        try {
          const ssService = new SalesShipmentService(this.$route.params.id, id)
          await ssService.deleteFullfillment()
          this.$message.success('Fulfillment was successfully deleted')

          this.getSalesOrder()
          this.$emit('update:update', true)
        } catch (error) {
          this.$Error(error)
        }
      }).catch(() => {})
    },
    async createFulfillment (id) {
      try {
        const ssService = new SalesShipmentService(this.$route.params.id, id)
        await ssService.createFulfillment()
        this.$message.success('Fulfillment was successfully created')
        this.getSalesOrder()
        this.$emit('update:update', true)
      } catch (error) {
        this.$Error(error)
      }
    },

    // Service Fetch
    async getSalesOrder () {
      try {
        this.loading = true
        this.title = null
        this.invoices = []

        const soService = new SalesOrderService(this.$route.params.id)
        const result = await soService.getSOandStatus()
        this.so = result.data

        if (this.so.invoiced !== 0) {
          const ssService = new SalesShipmentService(this.$route.params.id)

          const result2 = await ssService.getStatus()
          this.shipments = result2.data.rows

          const result3 = await ssService.getFulfillmentStatus()
          this.fulfillments = result3.data.rows
        }
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    }
  },
  created () {
    this.soService = new SalesOrderService()
  },
  async mounted () {
    await this.getSalesOrder()

    this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Sales Order', route: '' },
      { title: 'Order' },
      { title: this.so.so_number },
      { title: 'Shipments' }
    ])
  }
}
</script>
