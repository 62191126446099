import Service from '../Service'

export default class extends Service {
  constructor (salesOrderId, id) {
    const url = ['sales-order', 'orders', salesOrderId, 'shipments']
    if (id) {
      url.push(id)
    }
    super(url)
  }

  getStatus () {
    return this.get(null, '/status')
  }

  getFulfillmentStatus () {
    return this.get(null, '/fulfillment-status')
  }

  createFulfillment () {
    return this.post(null, '/fulfill')
  }

  deleteFullfillment () {
    return this.del('/fulfill')
  }
}
